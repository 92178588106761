export const Config = {
  //Values for MCAG, use these values when doing a commit because the pipeline will use them
  initiateLoginEndpoint: 'https://rtcapp.mondaycoffee.com/auth-start.html',
  clientId: '84fa4ea4-fd5d-4d4e-a94b-289a5a222980',
  //Values for STAGING
  //initiateLoginEndpoint: 'https://agreeable-water-0b5c86903.6.azurestaticapps.net/auth-start.html',
  //clientId: '551a5199-08ce-4f19-bb20-f765290b37b5',
  //Use these values while doing local development
  //initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  //clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
};
