import { MessageItem } from "../../interfaces/IMessageItem";
import { app } from '@microsoft/teams-js';
import { AppConstants } from '../../Constants/AppConstants';
import { TeamMember, TeamsChannel } from "../../interfaces/ITeamsChannel";
import { IMentionOption, MentionType } from "../../interfaces/IMentionOption";
import { GetGraphAccessToken } from "../HttpClientService/HttpClientService";
import LocalizationService from "../LocalizationService/LocalizationService";
import HttpHeaderHelper from "../../helpers/HttpHeadersHelper";
import axios from "axios";

export default class TeamsService {
    public static async getTeamsAppIdByName(appName: string, groupId: string): Promise<string>{
        let appId: string = '';

        if(!appName || appName.length === 0 || !groupId || groupId.length === 0){
            return appId;
        }

        try{
            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const result = await axios.get(`https://graph.microsoft.com/v1.0/teams/${groupId}/installedApps?$expand=teamsAppDefinition($select=teamsAppId)&$filter=teamsAppDefinition/displayName eq '${appName}'`, { headers: reqHeaders });

            if (result && result.data.value && Array.isArray(result.data.value) && result.data.value.length > 0) {
                appId = result.data.value[0].teamsAppDefinition.teamsAppId;
            }
        }catch(error){
            console.error(error);
        }
        
        return appId;
    }

    public static async getTabUrl(teamId: string, channelId: string, appId: string): Promise<string>{
        let tabUrl: string = '';

        if(!teamId || teamId.length === 0 || !channelId || channelId.length === 0 || !appId || appId.length === 0){
            return appId;
        }

        try{
            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const result = await axios.get(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/tabs?$filter=Contains(webUrl,'${appId}')&$select=webUrl`, { headers: reqHeaders });

            if (result && result.data.value && Array.isArray(result.data.value) && result.data.value.length > 0) {
                tabUrl = result.data.value[0].webUrl;
            }else{
                const resultTeamsStore = await axios.get(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/tabs?$filter=Contains(configuration/contentUrl,'rtcapp.mondaycoffee.com')&$select=webUrl`, { headers: reqHeaders });

                if (resultTeamsStore && resultTeamsStore.data.value && Array.isArray(resultTeamsStore.data.value) && resultTeamsStore.data.value.length > 0) {
                    tabUrl = resultTeamsStore.data.value[0].webUrl;
                }
            }
        }catch(error){
            console.error(error);
        }
        
        return tabUrl;
    }

    public static async getDeepLinkToMessage(message: MessageItem, teamsContext: app.Context): Promise<string>{
        let link: string = '';

        if(!message || !teamsContext){
            return link;
        }

        try{
            const groupId = teamsContext.team.groupId;

            const appId = await TeamsService.getTeamsAppIdByName(AppConstants.rtcAppName, groupId);
    
            const channelId = encodeURIComponent(teamsContext.channel.id);
    
            const tenantId = teamsContext.user.tenant.id;

            const tabWebUrl = await TeamsService.getTabUrl(groupId, channelId, appId);

            const entityUrl = tabWebUrl.split('?')[0];
   
            link = `${entityUrl}?label=${message.messageId}&context=%7B%22subEntityId%22%3A%22${message.messageId}%22%2C%22channelId%22%3A%22${channelId}%22%7D&groupId=${groupId}&tenantId=${tenantId}`;
        }catch(error){
            console.error(error);
        }
        
        return link;
    }

    public static async sendMessageToTeams(message: MessageItem, channelId: string, description: string, mentions: IMentionOption[], teamsContext: app.Context): Promise<boolean>{
        let ok: boolean = false;

        if(!message || !channelId || channelId.length === 0 || !teamsContext){
            return ok;
        }

        try{
            const mentionsArray = [];
 
            let mentionsString = '';

            if(mentions && mentions.length > 0){                  
                mentions.forEach((mention, index) => {
                    switch(mention.type){
                        case MentionType.Member:
                            {
                                const mentionObj = {
                                    id: index,
                                    mentionText: mention.label,
                                    mentioned: {
                                        user: {
                                            id: mention.key,
                                            displayName: mention.label,
                                            userIdentityType: "aadUser"
                                        }
                                    }
                                };
       
                                mentionsArray.push(mentionObj);
                            }
                            break;
                        case MentionType.Team:
                        case MentionType.Channel:
                            {
                                const mentionObj = {
                                    id: index,
                                    mentionText: mention.label,
                                    mentioned: {
                                        conversation: {
                                            id: mention.key,
                                            displayName: mention.label,
                                            conversationIdentityType: mention.type === MentionType.Channel ? 'channel' : 'team'
                                        }
                                    }
                                };
       
                                mentionsArray.push(mentionObj);
                            }
                            break;
                        case MentionType.Tag:
                            {  
                                const mentionObj = {
                                    id: index,
                                    mentionText: mention.label,
                                    mentioned: {
                                        tag: {
                                            id: mention.key,
                                            displayName: mention.label
                                        }
                                    }
                                };
       
                                mentionsArray.push(mentionObj);
                            }
                            break;
                    }                    
 
                    if(index === 0){
                        mentionsString = `<br /><at id='${index}'>${mention.label}</at>`;
                    }else{
                        mentionsString = mentionsString + ` <at id='${index}'>${mention.label}</at>`;
                    }
                });
            }

            const groupId = teamsContext.team.groupId;

            const link: string = await this.getDeepLinkToMessage(message, teamsContext);

            const loc = await LocalizationService.loadLocale(teamsContext.app.locale);

            const adaptiveCardBody = {
                type: "AdaptiveCard",
                $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
                version: "1.4",
                body: [                    
                    {
                        type: "TextBlock",
                        text: `${message.messageId} | ${message.messageRTCTitle}`,
                        wrap: true,
                        weight: "Bolder",
                        horizontalAlignment: "Left",
                        size: "large",
                        spacing: "none"
                    },
                    {
                        type: "TextBlock",
                        text: message.messageDatePublished,
                        wrap: true
                    }
                ],
                actions: [
                    {
                      type: "Action.OpenUrl",
                      title: (loc as IRTCresources).WebParts.Dialogs.viewMessage,
                      url: link
                    }
                ]
            };

            let customDescriptionString = '';
 
            if(description && description.trim().length > 0){
                customDescriptionString = `<br />${description.trim()}`;
            }

            const attachmentId = crypto['randomUUID']().replace('-','');

            const body = {
                "subject": "",
                "body": {
                    "contentType": "html",
                    "content": `<attachment id="${attachmentId}"></attachment>${customDescriptionString}${mentionsString}`
                },
                "attachments": [
                    {
                        "id": attachmentId,
                        "contentType": "application/vnd.microsoft.card.adaptive",
                        "contentUrl": null,
                        "content": JSON.stringify(adaptiveCardBody),
                        "name": null,
                        "thumbnailUrl": null
                    }
                ],
                "mentions": mentionsArray
            };

            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const channelUrl = `https://graph.microsoft.com/beta/teams/${groupId}/channels/${channelId}/messages`;

            const responsePostMessage = await axios.post(channelUrl, body, { headers: reqHeaders });

            if(responsePostMessage && responsePostMessage.data.id){
                ok = true;
            }
        }catch(error){
            console.error(error);
        }

        return ok;
    }

    public static async getTeamChannels(groupId: string): Promise<TeamsChannel[]>{
        const teamsChannels: TeamsChannel[] = [];

        if(!groupId || groupId.length === 0){
            return teamsChannels;
        }

        try{
            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const result = await axios.get(`https://graph.microsoft.com/v1.0/teams/${groupId}/channels?$select=id,displayName`, { headers: reqHeaders });

            if (result && result.data.value && Array.isArray(result.data.value) && result.data.value.length > 0) {
                result.data.value.forEach(element => {
                    teamsChannels.push({
                        id: element.id,
                        name: element.displayName,
                        teamId: groupId
                    })
                });
            }
        }catch(error){
            console.error(error);
        }

        return teamsChannels;
    }

    public static async getChannelMembers(groupId: string, channelId: string): Promise<TeamMember[]>{
        const members: TeamMember[] = [];

        if(!groupId || groupId.length === 0 || !channelId || channelId.length === 0){
            return members;
        }

        try{
            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const result = await axios.get(`https://graph.microsoft.com/beta/teams/${groupId}/channels/${channelId}/members`, { headers: reqHeaders });

            if (result && result.data.value && Array.isArray(result.data.value) && result.data.value.length > 0) {
                result.data.value.forEach(element => {
                    members.push({
                        id: element.userId,
                        name: element.displayName,
                    })
                });
            }
        }catch(error){
            console.error(error);
        }

        return members;
    }
}