/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable no-useless-escape */

namespace Utilities {
  export function encodeForOData(strInput: string): string {
    return strInput
      .replace(/\:/g, "%3A")
      .replace(/\./g, "%2E")
      .replace(/\#/g, "%23")
      .replace("@", "%40")
      .replace(/\s/g, "%20");
  }
}

export default Utilities;

export interface IAttachment {
  id?: string;
  contentType?: string;
  contentUrl?: string;
  name?: string;
  thumbnailUrl?: string;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));
